import { CuredApiPaths } from '../../models/enums';
import { UseMutationOptions } from 'react-query';
import { ApiMethods } from '../../models/enums';
import useMutation from '../useMutation';
import MutationKeys from './keys';
import { stringifyUrl } from 'query-string';
import { isRootFolder } from 'utils/folders';

const useDeleteLandingPage = (
    landingPageId: string,
    parentFolderId?: number,
    mutateOptions?: UseMutationOptions<unknown, Error>
) => {
    const url = stringifyUrl({
        url: `${CuredApiPaths.LANDING_PAGES}/${landingPageId}`,
        query: { ...(parentFolderId && !isRootFolder(parentFolderId) && { parentFolderId: parentFolderId }) },
    });
    return useMutation(MutationKeys.DELETE_LANDING_PAGE, url, mutateOptions, ApiMethods.DELETE);
};

export default useDeleteLandingPage;
