import { UseQueryOptions } from 'react-query';
import useQuery from 'hooks/useQuery';
import { CuredApiPaths } from 'models/enums';
import QueryKeys from './keys';
import { ContentType, ContentTypeEnumForBackend } from 'domains/content/types';
import { Folder } from 'models/types';

type UseContentWithFoldersParams = {
    folderId?: number;
    type?: string;
    reactQueryOptions?: UseQueryOptions<Folder[], Error>;
};

const useContentWithFolders = ({ folderId = -1, type, reactQueryOptions }: UseContentWithFoldersParams = {}) => {
    let apiType = type;
    if (type === ContentType.PAGE) {
        apiType = ContentTypeEnumForBackend['Landing Page'];
    }

    const axiosOptions = {
        ...(type && { params: { type: apiType } }),
    };

    const folderPath = folderId === -1 ? '' : folderId;

    return useQuery(
        QueryKeys.CONTENT(folderId, type),
        `${CuredApiPaths.CONTENT}/${folderPath}`,
        reactQueryOptions,
        axiosOptions
    );
};

export default useContentWithFolders;
