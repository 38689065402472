import { UseQueryOptions } from 'react-query';
import { CampaignChannels } from 'domains/campaigns/types';
import { CuredApiPaths } from 'models/enums';
import useQuery from 'hooks/useQuery';
import QueryKeys from './keys';

export type UseAudienceCountResponse = {
    all: string;
    email: string;
    sms: string;
    directmail: string;
};

export type UseAudienceCountWithChannelResponse = {
    count: string;
};

/**
 * A hook that returns the number of contacts for a given audience.
 * @param audienceId the id of the audience
 * @param reactQueryOptions the options for the react-query hook
 * @returns a react-query object
 */
const useAudienceCount = (
    audienceId: number,
    channel?: CampaignChannels,
    reactQueryOptions?: UseQueryOptions<UseAudienceCountResponse | UseAudienceCountWithChannelResponse, Error>
) => {
    const idString = audienceId?.toString();
    let audienceCountUri = `${CuredApiPaths.SEGMENTS}/${idString}/count`;
    if (channel) {
        audienceCountUri += `/${channel.toLowerCase()}`;
    }
    return useQuery(QueryKeys.AUDIENCE_COUNT(idString, channel), audienceCountUri, reactQueryOptions);
};

export default useAudienceCount;
