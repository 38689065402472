import { useFlags } from 'launchdarkly-react-client-sdk';
import SegmentsPageWithFolders from './SegmentsPageWithFolders';
import SegmentsPageWithoutFolders from './SegmentsPageWithoutFolders';

const SegmentsPage = () => {
    const { audienceFolders: hasAudienceFolders } = useFlags();

    if (hasAudienceFolders === undefined) return;
    return hasAudienceFolders ? <SegmentsPageWithFolders /> : <SegmentsPageWithoutFolders />;
};

export default SegmentsPage;
