import {
    ContentTypeEnumForBackend,
    ContentModalContentType,
    ContentTypeForComparison,
    LandingPage,
    Template,
    isTemplate as isTemplateTypeGuard,
} from 'domains/content/types';
import { URLPaths } from 'models/enums';
import { isRootFolder } from 'utils/folders';

export const extractContentId = (id?: number) => (id ? Number(String(id).split(':')[1]) : null);

export const getContentCreatePath = ({
    isEmail,
    id,
    isCopy,
    parentFolderId,
}: {
    isEmail: boolean;
    id?: number;
    isCopy?: boolean;
    parentFolderId?: number;
}) => {
    const basePath = isEmail ? URLPaths.CONTENT_CREATE_EMAIL : URLPaths.CONTENT_CREATE_PAGE;
    const copyQuery = isCopy ? '&isCopy=true' : '';
    const parentFolderQuery =
        parentFolderId && !isRootFolder(parentFolderId) ? `&parentFolderId=${parentFolderId}` : '';
    const idQuery = id ?? 'default';
    return `${basePath}?id=${idQuery}${parentFolderQuery}${copyQuery}`;
};

export const getContentType = (content: ContentModalContentType, hasContentFoldersFeature: boolean) => {
    if (!content) return;
    if (hasContentFoldersFeature) return content?.type;
    return isTemplateTypeGuard(content as Template | LandingPage)
        ? ContentTypeForComparison.EMAIL
        : ContentTypeForComparison.LANDING_PAGE;
};

export function contentTypeToSnakeCase(type: keyof typeof ContentTypeEnumForBackend): string {
    return ContentTypeEnumForBackend[type] || '';
}
