import { SupervisedUserCircleOutlined, Add as AddIcon } from '@mui/icons-material';
import { MenuItem, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { CampaignChannelLabels } from 'domains/campaigns/types';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import { selectAudience } from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { CampaignStateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import Autocomplete from 'domains/core/components/Autocomplete';
import FormLayout from 'domains/core/components/FormLayout';
import { AutocompleteOption } from 'domains/core/components/Autocomplete/Autocomplete';
import { Segment } from 'domains/segments/types';
import { hasInvalidRelativeDates } from 'domains/segments/utils';
import useAudienceCount, { UseAudienceCountWithChannelResponse } from 'hooks/queries/useAudienceCount';
import useSegments from 'hooks/queries/useSegments';
import { URLPaths } from 'models/enums';
import { getAudienceLabel } from 'utils';

const renderOption = ({
    history,
    option,
    props,
}: {
    history: ReturnType<typeof useHistory>;
    option: AutocompleteOption<number>;
    props: React.HTMLAttributes<HTMLLIElement>;
}) => {
    const { index, label, value } = option;
    const isCreateNewAudienceOption = index === 0;

    if (isCreateNewAudienceOption) {
        return (
            <MenuItem {...props} onClick={() => history.push(URLPaths.SEGMENT_CREATE)}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography>Create New</Typography>
                    <AddIcon fontSize="inherit" />
                </Stack>
            </MenuItem>
        );
    }
    return <MenuItem {...props} children={label} key={value} />;
};

type Props = {
    activeSendIndex: number;
    campaignState: CampaignStateTypes;
    dispatchCampaignAction: React.Dispatch<any>;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
    setNextStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignAudienceStep = ({
    activeSendIndex,
    campaignState,
    dispatchCampaignAction,
    setIsContinueEnabled,
    setNextStep,
}: Props) => {
    const channel = campaignState.sends[activeSendIndex].channel;

    const [audienceDropdownItems, setAudienceDropdownItems] = useState<AutocompleteOption<number>[]>();
    const [inputValue, setInputValue] = useState('');
    const [selectedAudienceOption, setSelectedAudienceOption] = useState<AutocompleteOption<number>>();

    const history = useHistory();

    const { isSuccess: isAudiencesSuccess, data: audiences } = useSegments();

    const selectedAudienceId = campaignState.sends[0].segmentId;

    const { data, isError, isFetching } = useAudienceCount(selectedAudienceId, channel, {
        enabled: !!selectedAudienceId,
    });
    const audienceCountData = data as UseAudienceCountWithChannelResponse;

    useEffect(() => {
        setNextStep(CustomCampaignSteps.SELECT_CONTENT);
        // The dependency array is empty because this useEffect should run exactly once after the initial rendering of the component.
        // The hook is not dependent on any props or state variables to re-run. This is intended to perform the initial setup based on
        // the `activeSendIndex` value when the component mounts without reacting to any further updates or re-renders.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsContinueEnabled(!!selectedAudienceId);
    }, [selectedAudienceId, setIsContinueEnabled]);

    useEffect(() => {
        if (isAudiencesSuccess) {
            // If found, moves the "All Contacts" audience to the beginning of the audienceDropdownItems array state.
            // If not found, doesn't add 'undefined' to reorderedAudiences.
            const allAudience = audiences.find((audience) => audience.name === 'All Contacts');
            const otherAudience = audiences.filter((audience) => audience.name !== 'All Contacts');
            const reorderedAudiences = [...(allAudience ? [allAudience] : []), ...otherAudience];

            const dropdownItemsWithoutInvalidRelativeDates: Partial<Segment>[] = reorderedAudiences.filter(
                (audience) => !hasInvalidRelativeDates(audience)
            );

            // Adds a "Create new" item to the beginning of the audienceDropdownItems array state.
            const dropdownItemsWithCreateNew = [
                { index: 0, label: 'Create new', value: null },
                ...dropdownItemsWithoutInvalidRelativeDates.map((audience, index) => ({
                    index: index + 1,
                    label: getAudienceLabel({
                        audience,
                        channel: CampaignChannelLabels[channel],
                        count: audienceCountData?.count,
                        isError,
                        isLoading: isFetching,
                        selectedAudienceId,
                    }),
                    value: audience.id,
                })),
            ];
            setAudienceDropdownItems(dropdownItemsWithCreateNew);

            if (selectedAudienceId) {
                const filteredAudience = dropdownItemsWithCreateNew.filter(
                    (option) => option.value === selectedAudienceId
                )[0];
                const filteredAudienceOption = { index: 1, label: filteredAudience.label, value: selectedAudienceId };

                setSelectedAudienceOption(filteredAudienceOption);
                setInputValue(filteredAudienceOption.label);
            }
        }
    }, [audiences, channel, isError, isFetching, isAudiencesSuccess, selectedAudienceId, audienceCountData]);

    const handleAudienceSelection = (option?: { index: number; label: string; value: number }) => {
        if (!option) {
            dispatchCampaignAction(selectAudience(null));
            setSelectedAudienceOption(null);
        } else {
            dispatchCampaignAction(selectAudience(option.value));
            setSelectedAudienceOption(option);

            const audienceLabel = getAudienceLabel({
                audience: { id: option.value },
                channel: CampaignChannelLabels[channel],
                count: audienceCountData?.count,
                isError,
                isLoading: isFetching,
                selectedAudienceId,
            });
            setInputValue(audienceLabel);
        }
    };

    return (
        <FormLayout
            header="select audience"
            stepName="audience"
            icon={<SupervisedUserCircleOutlined />}
            guidingText="Select an audience from the dropdown to choose the contacts you want to connect with in this Curation."
        >
            <Autocomplete
                data-test="custom-curation-audience-autocomplete"
                freeSolo={false}
                fullWidth
                getOptionLabel={(option) => option.label ?? ''}
                inputValue={inputValue ?? ''}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                label="Audience"
                onChange={(e: any, option) => handleAudienceSelection(option)}
                onInputChange={(e: any, inputValue) => setInputValue(inputValue)}
                options={audienceDropdownItems ?? []}
                placeholder="Select or create audience"
                renderOption={({ className, ...props }, option) => renderOption({ history, option, props })}
                type="string"
                value={selectedAudienceOption ?? null}
            />
        </FormLayout>
    );
};

export default CustomCampaignAudienceStep;
