import { useCallback, useState, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import TextField from 'domains/core/components/TextField';
import { Segment } from 'domains/segments/types';
import useUpdateSegment from 'hooks/mutations/useUpdateSegment';
import QueryKeys from 'hooks/queries/keys';
import useSegments from 'hooks/queries/useSegments';

export type Props = {
    id: number;
    name: string;
    onSave?: () => void;
};

const SegmentNameInput = ({ id, name, onSave }: Props) => {
    const queryClient = useQueryClient();
    const [value, setValue] = useState(name);
    const [error, setError] = useState(false);
    const { data, isLoading } = useSegments({
        /*
            If the user is editing one name and it is a duplicate, then changes the original to something else, 
            the name is no longer a duplicate so we have to reset the error state.
        */
        onSettled: () => validateNameUniqueness(value),
    });

    // Add the names of all the segments to an object on load, so we can check if the name exists in constant time.
    const segmentMap: Record<string, number> = useMemo(() => {
        if (isLoading) {
            return {};
        }

        return data.reduce((map: Record<string, number>, segment) => {
            const lowerCaseName = segment.name.toLowerCase().trim();
            map[lowerCaseName] = segment.id;
            return map;
        }, {});
    }, [data, isLoading]);

    const validateNameUniqueness = useCallback(
        (newName: string) => {
            const isDuplicateName = data?.some((segment) => {
                // You can change the case of an existing segment name, but when checking to see if the name is taken the case is insensitive.
                if (segment.id === id) {
                    return (
                        newName.toLowerCase().trim() !== name.toLowerCase().trim() &&
                        Boolean(segmentMap[newName.toLowerCase().trim()])
                    );
                } else {
                    return (
                        newName !== name &&
                        Boolean(segmentMap[newName.toLowerCase().trim()]) &&
                        segmentMap[newName.toLowerCase().trim()] !== id
                    );
                }
            });
            if (isDuplicateName) {
                setError(true);
            } else {
                setError(false);
            }
        },
        [data, id, name, segmentMap]
    );

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        validateNameUniqueness(e.target.value);
        setValue(e.target.value);
    };

    const { mutate, isError, error: mutationError } = useUpdateSegment(id, {
        onMutate: async () => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(QueryKeys.SEGMENTS);

            // Snapshot the previous value
            const previousSegments: Segment[] = queryClient.getQueryData(QueryKeys.SEGMENTS);

            // Optimistically update to the new value
            const newSegments = previousSegments.map((previousSegment) =>
                previousSegment.id === id ? { ...previousSegment, name: value } : previousSegment
            );
            queryClient.setQueryData(QueryKeys.SEGMENTS, () => newSegments);

            // Return a context object with the snapshotted value
            return { previousSegments };
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newSegment, context) => {
            // @ts-ignore
            queryClient.setQueryData(QueryKeys.SEGMENTS, context.previousSegments);
            // @ts-ignore
            setValue(context.previousSegments.find((segment) => segment.id === id).name);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(QueryKeys.SEGMENTS);
        },
        onSuccess: onSave,
    });

    return (
        <>
            {isError && <ErrorSnackbar errorMessage={mutationError.message} />}
            <TextField
                data-testid="update-name-input"
                withClickAway
                onCancel={() => {
                    setValue(name);
                    setError(false);
                }}
                onClickAway={() => mutate({ name: value })}
                label="Audience name"
                size="small"
                value={value}
                onChange={handleNameChange}
                width="65%"
                focused
                hidePencilUntilHovered
                helperText={error ? 'Duplicate audience name.' : undefined}
                error={error}
            />
        </>
    );
};

export default SegmentNameInput;
