import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { getNumberWithCommas } from 'utils';
import { URLPaths } from 'models/enums';
import useAudienceCount, { UseAudienceCountWithChannelResponse } from 'hooks/queries/useAudienceCount';
import useCampaigns from 'hooks/queries/useCampaigns';
import useSegment from 'hooks/queries/useSegment';
import { Campaign } from 'domains/campaigns/types';
import DetailItem from 'domains/core/components/DetailItem';
import AutocompleteWithClickAway from 'domains/core/components/AutocompleteWithClickAway';

type Props = {
    campaign: Campaign;
    onChange?: () => void;
};

const CurationListAndFirstAudience = ({ campaign, onChange }: Props) => {
    const segmentId = campaign.sends[0].segmentId;
    const { data: segment, isSuccess: isSegmentSuccess } = useSegment(segmentId, { enabled: !!segmentId });
    const { data: segmentCount, isSuccess: isSegmentCountSuccess } = useAudienceCount(segmentId, campaign.channel);

    const history = useHistory();
    const { data: campaigns } = useCampaigns();

    const campaignOptions = useMemo(() => campaigns?.map((campaign) => ({ id: campaign.id, name: campaign.name })), [
        campaigns,
    ]);

    const countData = segmentCount as UseAudienceCountWithChannelResponse;

    const segmentDetailName = isSegmentSuccess ? segment.name : '';
    const segmentDetailCount = isSegmentCountSuccess ? getNumberWithCommas(Number(countData?.count)) : '';

    const segmentDescription = `${segmentDetailName} ${segmentDetailCount && `(${segmentDetailCount})`}`;

    return (
        <>
            <AutocompleteWithClickAway
                header="Curation"
                description={campaign.name}
                options={campaignOptions}
                option={campaign}
                optionLabelKey="name"
                optionValueKey="id"
                onChange={(option) => {
                    history.push(`${URLPaths.CURATIONS}/${option.value}/summary`);
                    onChange && onChange();
                }}
            />
            <DetailItem header="Audience" description={segmentDescription} />
        </>
    );
};

export default CurationListAndFirstAudience;
