import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { MenuItem } from '@mui/material';
import { getAudienceLabel } from 'utils';
import { URLPaths } from 'models/enums';
import useSegments from 'hooks/queries/useSegments';
import useAudienceCount, { UseAudienceCountWithChannelResponse } from 'hooks/queries/useAudienceCount';
import { Segment } from 'domains/segments/types';
import Autocomplete from 'domains/core/components/Autocomplete';
import CreateNewDropDownItem from 'domains/core/components/CreateNewDropDownItem';
import { CampaignChannels } from 'domains/campaigns/types';
import { hasInvalidRelativeDates } from 'domains/segments/utils';
import { AutocompleteOption } from 'domains/core/components/Autocomplete/Autocomplete';

type Props = {
    audienceId: number;
    editAudience: (audienceId: number) => void;
    channel?: CampaignChannels;
    label?: string;
    labelIcon?: JSX.Element;
    labelIconTooltipText?: string;
};

const CurationAudienceDropdown = ({
    editAudience,
    audienceId,
    channel,
    label = 'Audience',
    labelIcon,
    labelIconTooltipText,
}: Props) => {
    const { isSuccess: isAudiencesSuccess, data: audiences } = useSegments();

    const { data, isError, isFetching } = useAudienceCount(audienceId, channel, {
        enabled: Boolean(audienceId),
    });
    const audienceCountData = data as UseAudienceCountWithChannelResponse;

    const history = useHistory();

    const [audienceDropdownItems, setAudienceDropdownItems] = useState<AutocompleteOption<number>[]>();
    const [selectedAudience, setSelectedAudience] = useState<AutocompleteOption<number>>();
    const [textfieldInput, setTextfieldInput] = useState('');

    const handleCreateNew = () => {
        history.push(URLPaths.SEGMENT_CREATE);
    };

    const onChange = (e: any, option: AutocompleteOption<number>) => {
        if (option) {
            setSelectedAudience(option);
            editAudience(option.value);

            const audienceLabel = getAudienceLabel({
                audience: { id: option.value },
                channel,
                count: audienceCountData?.count,
                isError,
                isLoading: isFetching,
                selectedAudienceId: audienceId,
            });
            setTextfieldInput(audienceLabel);
        }
    };

    useEffect(() => {
        if (isAudiencesSuccess) {
            // Moves the "All Contacts" audience to the beginning of the audienceDropdownItems array state.
            const reorderedAudiences = [
                audiences.find((audience) => audience.name === 'All Contacts'),
                ...audiences.filter((audience) => audience.name !== 'All Contacts'),
            ];

            const dropdownItemsWithoutInvalidRelativeDates: Partial<Segment>[] = reorderedAudiences.filter(
                (audience) => !hasInvalidRelativeDates(audience)
            );

            // Adds a "Create new" item to the beginning of the audienceDropdownItems array state.
            const dropdownItemsWithCreateNew = [
                { index: 0, label: 'Create new', value: null },
                ...dropdownItemsWithoutInvalidRelativeDates.map((audience, index) => ({
                    index: index + 1,
                    label: getAudienceLabel({
                        audience,
                        channel,
                        count: audienceCountData?.count,
                        isError,
                        isLoading: isFetching,
                        selectedAudienceId: audienceId,
                    }),
                    value: audience.id,
                })),
            ];
            setAudienceDropdownItems(dropdownItemsWithCreateNew);

            if (audienceId) {
                const filteredAudience = dropdownItemsWithCreateNew.filter((option) => option.value === audienceId)[0];
                const filteredAudienceOption = { index: 1, label: filteredAudience.label, value: audienceId };

                setSelectedAudience(filteredAudienceOption);
                setTextfieldInput(filteredAudienceOption.label);
            }
        }
    }, [audienceCountData, isError, isFetching, audiences, isAudiencesSuccess, audienceId, channel]);

    return (
        <Autocomplete
            data-test="curation-audience-autocomplete"
            freeSolo={false}
            fullWidth
            getOptionLabel={(option) => option.label ?? ''}
            inputValue={textfieldInput ?? ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            label={label}
            labelIcon={labelIcon}
            labelIconTooltipText={labelIconTooltipText}
            onChange={onChange}
            onInputChange={(e: any, inputValue) => {
                setTextfieldInput(inputValue);
            }}
            options={audienceDropdownItems ?? []}
            placeholder="Select or create audience"
            renderOption={({ className, ...rest }, { index, label, value }) => {
                const shouldRenderTopItem = index === 0;

                if (shouldRenderTopItem) {
                    return (
                        <CreateNewDropDownItem
                            {...rest}
                            children={label}
                            key={value}
                            onClick={handleCreateNew}
                            value={value}
                        />
                    );
                }
                return <MenuItem {...rest} children={label} key={value} value={value} />;
            }}
            type="string"
            value={selectedAudience ?? null}
        />
    );
};

export default CurationAudienceDropdown;
