import { FolderOpenOutlined, MailOutline, NavigateNext, Wysiwyg } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { CSSProperties } from 'react';
import { ContentTypeForComparison, FolderBreadcrumbData, RootFolder } from 'domains/content/types';
import IconButton from 'domains/core/components/IconButton';
import ContentIcon from 'domains/core/components/SvgIcons/ContentIcon';
import theme from 'theme';
import { Folder } from 'models/types';

const sharedRowStyles = {
    alignItems: 'center',
    borderRadius: '6px',
    display: 'flex',
    fontSize: '1rem',
    height: '48px',
    justifyContent: 'flex-start',
    padding: '0 1rem',
};

const clickableRowStyles: CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    height: '100%',
};

const currentLocationRowStyles = {
    fontWeight: 'bold',
    pointerEvents: 'none',
};

const sharedInteractiveStyles = {
    cursor: 'pointer',
    '&:hover': {
        background: theme.palette.action.hover,
    },
};

const selectedRowStyles = {
    background: theme.palette.action.hover,
};

const variant = 'body2';

type Props = {
    activeFolder?: FolderBreadcrumbData | typeof RootFolder;
    content?: Folder;
    isRowSelected?: boolean;
    isPreview?: boolean;
    setFutureLocation?: (location?: Folder | typeof RootFolder) => void;
    setPreviewedLocation?: (location?: Folder) => void;
};

const ContentRow = ({
    activeFolder,
    content,
    isPreview,
    isRowSelected,
    setFutureLocation,
    setPreviewedLocation,
}: Props) => {
    const getContentIsInFolder = (id: number) => id === activeFolder.id;

    const handleRowClick = (contentIsInFolder: boolean) => {
        if (contentIsInFolder) return;
        if (isRowSelected) {
            setFutureLocation(null);
        } else {
            const futureLocation = content ?? RootFolder;
            setFutureLocation(futureLocation);
        }
    };

    if (!content) {
        const contentIsInRoot = getContentIsInFolder(RootFolder.id);

        return (
            <Typography
                onClick={() => handleRowClick(contentIsInRoot)}
                sx={{
                    ...sharedRowStyles,
                    ...(contentIsInRoot ? currentLocationRowStyles : sharedInteractiveStyles),
                    ...(isRowSelected && selectedRowStyles),
                }}
                variant={variant}
            >
                <ContentIcon sx={{ marginRight: 1 }} />
                {RootFolder.name}
            </Typography>
        );
    }

    const getContentIcon = () => {
        if (content.type === ContentTypeForComparison.EMAIL) return MailOutline;
        if (content.type === ContentTypeForComparison.LANDING_PAGE) return Wysiwyg;
        if (content.type === ContentTypeForComparison.FOLDER) return FolderOpenOutlined;
    };
    const Icon = getContentIcon();

    if (isPreview) {
        return (
            <Typography variant={variant} sx={sharedRowStyles}>
                <Icon sx={{ marginRight: 1 }} />
                {content.name}
            </Typography>
        );
    }

    const contentIsInFolder = getContentIsInFolder(content.id);

    const handlePreviewClick = () => {
        setPreviewedLocation(content);
        if (!contentIsInFolder) setFutureLocation(content);
    };

    return (
        <Typography
            sx={{
                ...sharedRowStyles,
                ...(!contentIsInFolder && sharedInteractiveStyles),
                ...(contentIsInFolder && {
                    fontWeight: 'bold',
                }),
                ...(isRowSelected && selectedRowStyles),
                justifyContent: 'space-between',
            }}
            variant={variant}
        >
            <span style={clickableRowStyles} onClick={() => handleRowClick(contentIsInFolder)}>
                <Icon sx={{ marginRight: 1 }} />
                {content.name}
            </span>
            <IconButton title="preview content" handleClick={handlePreviewClick} Icon={NavigateNext} />
        </Typography>
    );
};

export default ContentRow;
