import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import EmailIconButton from 'domains/campaigns/pages/CurationsPage/CampaignDetailsPage/EmailIconButton';
import { CampaignChannels, SendRecurrenceFrequency } from 'domains/campaigns/types';
import { getIsRecurrenceContinuous, getIsRecurrenceEnabled, getRecurrenceLabel } from 'domains/campaigns/utils';
import { isTemplate } from 'domains/content/types';
import { formatDateTime } from 'domains/core/components/DateRangeText/DateRangeText';
import DetailItem from 'domains/core/components/DetailItem';
import useAppSendSenderProfileTemplate from 'hooks/queries/useAppSendSenderProfileTemplate';
import useDirectMail from 'hooks/queries/useDirectMail';
import useTemplate from 'hooks/queries/useTemplate';
import { getFormattedLocalTime } from 'utils';

type Props = {
    channel?: CampaignChannels;
    firstEmailRecurrenceFrequency?: SendRecurrenceFrequency;
    header?: string;
    isFirstEmail?: boolean;
    isPreviewOpen: boolean;
    recurrenceFrequency?: SendRecurrenceFrequency;
    scheduledEndTimestamp?: string;
    scheduledTimestamp?: string;
    timeDelay?: string;
    onClick?: () => void;
};

type ContentDetailItemProps = Props & {
    contentName: string;
};

type ContentDetailByIdProps = Props & {
    contentId: number;
    isSMS?: boolean;
};

type ContentDetailProps = ContentDetailByIdProps & {
    isCampaign?: boolean;
};

const ContentDetail = ({ channel, isCampaign, ...rest }: ContentDetailProps) => {
    if (channel === CampaignChannels.DIRECT_MAIL) {
        return <ContentDetailByDirectMailId {...rest} />;
    }
    if (isCampaign) {
        return <ContentDetailBySendId {...rest} />;
    }
    return <ContentDetailByTemplateId {...rest} />;
};

export default ContentDetail;

export const ContentDetailByDirectMailId = ({
    contentId,
    header,
    isPreviewOpen,
    scheduledTimestamp,
}: ContentDetailByIdProps) => {
    const { data, isLoading, isSuccess } = useDirectMail(contentId, {
        enabled: Boolean(contentId),
    });

    const getContentName = () => {
        if (isLoading) return 'Loading...';
        if (!isSuccess) return 'Problem getting name.';
        return data.name;
    };
    const contentName = getContentName();

    return (
        <ContentDetailItem
            contentName={contentName}
            header={header}
            isPreviewOpen={isPreviewOpen}
            scheduledTimestamp={scheduledTimestamp}
        />
    );
};

export const ContentDetailBySendId = ({ contentId, isSMS, ...rest }: ContentDetailByIdProps) => {
    const { data: contentDetail, isSuccess: isContentDetailSuccess } = useAppSendSenderProfileTemplate(contentId, {
        enabled: Boolean(contentId),
    });

    const getContentName = () => {
        if (!isContentDetailSuccess || !contentDetail) return '';

        return isSMS ? contentDetail.contentSnapshot : contentDetail.templateName;
    };

    return <ContentDetailItem {...rest} contentName={getContentName()} />;
};

export const ContentDetailByTemplateId = ({ contentId, ...rest }: ContentDetailByIdProps) => {
    const { data: template } = useTemplate({ templateId: `${contentId}` });
    const templateName = isTemplate(template) ? template.name : '';

    return <ContentDetailItem {...rest} contentName={templateName} />;
};

export const ContentDetailItem = ({
    firstEmailRecurrenceFrequency,
    header,
    isFirstEmail,
    isPreviewOpen,
    recurrenceFrequency,
    scheduledEndTimestamp,
    scheduledTimestamp,
    timeDelay,
    contentName,
    onClick,
}: ContentDetailItemProps) => {
    const renderScheduledInfo = () => {
        const formattedScheduledTimestamp = formatDateTime(scheduledTimestamp);
        const isRecurringCampaign = getIsRecurrenceEnabled(firstEmailRecurrenceFrequency);

        if (isFirstEmail) {
            const recurrenceLabel = getRecurrenceLabel(recurrenceFrequency, scheduledTimestamp);
            const recurrenceLabelForDislay = recurrenceLabel.charAt(0).toUpperCase() + recurrenceLabel.slice(1);

            const hasEndDate = !!scheduledEndTimestamp;
            const formattedScheduledEndTimestamp = formatDateTime(scheduledEndTimestamp);

            return (
                <Stack>
                    {isRecurringCampaign && (
                        <div data-testid="curations-summary-recurrence-label">{recurrenceLabelForDislay}</div>
                    )}
                    <div>
                        {formattedScheduledTimestamp}
                        {hasEndDate && ' -'}
                    </div>
                    {hasEndDate && (
                        <div data-testid="curations-summary-scheduled-end-timestamp">
                            {formattedScheduledEndTimestamp}
                        </div>
                    )}
                </Stack>
            );
        }

        if (isRecurringCampaign) {
            const formattedScheduledTime = getFormattedLocalTime(DateTime.fromISO(scheduledTimestamp).toISO());
            const isRecurrenceContinuous = getIsRecurrenceContinuous(firstEmailRecurrenceFrequency);
            return (
                <Stack>
                    {timeDelay}s after previous send{isRecurrenceContinuous ? '' : ` at ${formattedScheduledTime}`}
                </Stack>
            );
        }

        return <Stack>{formattedScheduledTimestamp}</Stack>;
    };

    return (
        <DetailItem
            description={contentName}
            descriptionIcon={!!onClick ? <EmailIconButton onClick={onClick} /> : null}
            descriptionIconTooltip="Click to preview email."
            header={header}
            footer={renderScheduledInfo()}
            isActive={isPreviewOpen}
        />
    );
};
