import { Stack } from '@mui/material';
import { getAudienceLabel } from 'utils';
import useAudienceCount, { UseAudienceCountWithChannelResponse } from 'hooks/queries/useAudienceCount';
import { CampaignChannels, ContentFields, ScheduleFields } from 'domains/campaigns/types';
import { Segment } from 'domains/segments/types';
import CampaignDetails, { Detail } from 'domains/campaigns/components/CampaignDetails/CampaignDetails';
import DetailItem from 'domains/core/components/DetailItem';

export type Props = {
    // Required for CS campaigns
    details?: Detail[];
    // Required for SS & TD campaigns
    contentPreviewNodeId?: string;
    contentFieldsAll?: ContentFields[];
    scheduleFieldsAll?: ScheduleFields[];
    contentNodeIds?: string[];
    setContentPreviewNodeId?: (contentPreviewNodeId: string) => void;
    // Required for both
    audience?: Segment; // Audience should be provided to render the name and count, but this component will render if it is undefined while loading.
};

const CampaignPublishForm = ({
    contentPreviewNodeId,
    contentFieldsAll,
    details,
    scheduleFieldsAll,
    contentNodeIds,
    setContentPreviewNodeId,
    audience,
}: Props) => {
    const audienceId = audience?.id;
    const { data, isLoading, isError } = useAudienceCount(audienceId, CampaignChannels.EMAIL, {
        enabled: Boolean(audienceId),
    });
    const audienceCountData = data as UseAudienceCountWithChannelResponse;

    // Assumes that each contentFields corresponds to a scheduleFields.
    const emailDetails = contentFieldsAll?.map((contentFields, index) => {
        const scheduleFields = scheduleFieldsAll[index]; // Aligns with the contentFields by index.
        return {
            contentNodeId: contentNodeIds[index],
            contentId: contentFields.templateId,
            parentAudienceDelayInterval: scheduleFields?.parentAudienceDelayInterval,
            recurrenceFrequency: scheduleFields?.recurrenceFrequency,
            scheduledEndTimestamp: scheduleFields?.scheduledEndTimestamp,
            scheduledTimestamp: scheduleFields?.scheduledTimestamp,
            onClick: setContentPreviewNodeId,
        };
    });

    const description = audience
        ? getAudienceLabel({
              audience,
              channel: CampaignChannels.EMAIL,
              count: audienceCountData?.count,
              isError,
              isLoading,
              selectedAudienceId: audienceId,
          })
        : '';

    return (
        <Stack alignItems="left" rowGap={4}>
            <DetailItem header="Audience" description={description} />
            <CampaignDetails contentPreviewNodeId={contentPreviewNodeId} details={details ?? emailDetails} />
        </Stack>
    );
};

export default CampaignPublishForm;
