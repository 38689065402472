/* eslint-disable @typescript-eslint/no-unused-vars */
import { MarkunreadMailboxOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import mime from 'mime';
import { useEffect, useState } from 'react';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import {
    resetContent,
    setContent,
    setSenderProfileId,
} from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { CampaignStateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { DirectMailSendInputState } from 'domains/campaigns/types';
import FormLayout from 'domains/core/components/FormLayout';
import { AllowedMimeTypes } from 'models/enums';
import useUploadPdf from 'hooks/mutations/useUploadPdf';
import useSenderProfiles from 'hooks/queries/useSenderProfiles';
import useTemplates from 'hooks/queries/useTemplates';
import theme from 'theme';
import InputLabel from 'domains/core/components/InputLabel';
import Select from 'domains/core/components/Select';
import { DirectMailSenderProfile } from 'domains/content/types';
import FileUploader from './FileUploader';
import AlertMessage from './AlertMessage';
import { formatAddressLabel } from './utils';

const SAMPLE_PDF_URL = '/assets/sample.pdf';
const GUIDING_TEXT = (
    <>
        Focus on the goal of your customer interaction and upload a PDF file that aligns with your objective. To ensure
        your file meets the required format, you can download a sample file{' '}
        <a
            href={SAMPLE_PDF_URL}
            download="sample.pdf"
            style={{ color: theme.palette.secondary.main, textDecoration: 'underline' }}
        >
            here
        </a>{' '}
        for reference.
    </>
);

type Props = {
    activeSendIndex: number;
    campaignState: CampaignStateTypes;
    contentPreviewId?: number;
    dispatchCampaignAction: React.Dispatch<any>;
    setContentPreviewId: (id: number) => void;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
    setNextStep: (step: CustomCampaignSteps) => void;
};

const DirectMailContentStep = ({
    activeSendIndex,
    campaignState,
    contentPreviewId, // TODO: When previewing content is ready
    dispatchCampaignAction,
    setContentPreviewId, // TODO: When previewing content is ready
    setIsContinueEnabled,
    setNextStep,
}: Props) => {
    const [alertText, setAlertText] = useState('');
    const [isError, setIsError] = useState(false);

    const currentSend = campaignState.sends[activeSendIndex];
    const { content, senderProfileId } = currentSend as DirectMailSendInputState;

    // TODO: Get mail contents
    const { data: templates } = useTemplates();
    const { data: senderProfiles } = useSenderProfiles({ type: 'direct-mail' });

    const { mutate: uploadPdf, isLoading: isUploadPdfLoading } = useUploadPdf({
        onSuccess: ({ contentId }, formData) => {
            const fileObject = formData.get('fileObject');

            if (fileObject instanceof File) {
                dispatchCampaignAction(setContent({ contentId, contentName: fileObject.name }, activeSendIndex));
                setAlertText('File attached.');
            }
        },
        onError: (error) => {
            setIsError(true);
            setAlertText(`File was not attached. ${error.message}`);
        },
    });

    useEffect(() => {
        if (activeSendIndex > 0) {
            setNextStep(CustomCampaignSteps.CHOOSE_NEXT_STEP);
        } else {
            setNextStep(CustomCampaignSteps.SCHEDULE_CONTENT);
        }
        // The dependency array is empty because this useEffect should run exactly once after the initial rendering of the component.
        // The hook is not dependent on any props or state variables to re-run. This is intended to perform the initial setup based on
        // the `activeSendIndex` value when the component mounts without reacting to any further updates or re-renders.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const isEnabled = !!content.id && !!senderProfileId;
        setIsContinueEnabled(isEnabled);
    }, [content.id, senderProfileId, setIsContinueEnabled]);

    const senderProfilesValues =
        senderProfiles?.map((profile: DirectMailSenderProfile) => ({
            value: profile.id,
            label: formatAddressLabel(profile),
        })) || [];

    const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) {
            return;
        }

        const mimeType = mime.getType(file.name);
        const isAllowedMimeType = mimeType === AllowedMimeTypes.APPLICATION_PDF;

        if (!isAllowedMimeType) {
            dispatchCampaignAction(resetContent(activeSendIndex));
            setAlertText('Invalid file format. Please choose a valid file format.');
            setIsError(true);
            return;
        }

        const formData = new FormData();
        formData.append('fileObject', file);

        uploadPdf(formData);
        setAlertText('');
        setIsError(false);
    };

    const onRemoveUploadButtonClick = () => {
        dispatchCampaignAction(resetContent(activeSendIndex));
        setAlertText('');
        setIsError(false);
    };

    const onSenderProfileChange = (e: any) =>
        dispatchCampaignAction(setSenderProfileId(e.target.value, activeSendIndex));

    return (
        <FormLayout
            header="select content"
            icon={<MarkunreadMailboxOutlined />}
            guidingText={GUIDING_TEXT}
            stepName="direct mail"
        >
            <Stack rowGap={2}>
                <FileUploader
                    fileName={content.name}
                    isUploadPdfLoading={isUploadPdfLoading}
                    onFileInputChange={onFileInputChange}
                    onRemoveUploadButtonClick={onRemoveUploadButtonClick}
                />
                <AlertMessage isError={isError} text={alertText} />
                <Select
                    fullWidth
                    label={<InputLabel label="Sender profile" labelIconTooltipText="Who is sending this?" />}
                    menuItemStyles={{ textTransform: 'none' }}
                    onChange={onSenderProfileChange}
                    required
                    value={senderProfileId ?? ''}
                    values={senderProfilesValues}
                />
            </Stack>
        </FormLayout>
    );
};

export default DirectMailContentStep;
