import { AutoFixHigh } from '@mui/icons-material';
import { Box, Grid, Stack, SxProps, Theme, Typography, TypographyProps } from '@mui/material';
import { Location } from 'history';
import { ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Query, useQueryClient } from 'react-query';
import theme from 'theme';
import { URLPaths } from 'models/enums';
import { isTemplate } from 'domains/content/types';
import useQueryParams from 'hooks/useQueryParams';
import useSenderProfiles from 'hooks/queries/useSenderProfiles';
import useTemplate from 'hooks/queries/useTemplate';
import useTemplateSuggestedSubjectLines from 'hooks/queries/useTemplateSuggestedSubjectLines';
import useUpdateTemplate from 'hooks/mutations/useUpdateTemplate';
import Button from 'domains/core/components/Button';
import HtmlRenderer from 'domains/core/components/HtmlRenderer';
import IconButton from 'domains/core/components/IconButton';
import InputLabel from 'domains/core/components/InputLabel';
import Select from 'domains/core/components/Select';
import TextField from 'domains/core/components/TextField';
import SubjectLinesDialog from 'domains/campaigns/components/SubjectLinesDialog';
import PageInfoIcon from 'domains/core/components/SvgIcons/PageInfoIcon';
import PageHeader from 'domains/core/components/PageHeader';
import RouteLeavingGuard from 'domains/core/components/RouteLeavingGuard';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';

const inputSectionSx: SxProps<Theme> = {
    rowGap: 2,
};
const headerStackSx: SxProps<Theme> = {
    flexDirection: 'row',
    columnGap: 2,
};
const previewSx: SxProps<Theme> = {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '6px',
    boxShadow: `2px 2px 0 ${theme.palette.primary.main}`,
    height: '75vh',
    overflow: 'scroll',
};

const headerProps = {
    lineHeight: 2.25,
    variant: 'body2' as TypographyProps['variant'],
};
const textFieldProps = {
    fullWidth: true,
    withCounter: true,
};
const dialogProps = {
    title: `Don't lose your work!`,
    contentText: `Before you go, it looks like you've made some changes. If you exit without saving, any changes you've made to Send Details will be lost.`,
    cancelButtonText: 'Exit without saving',
    confirmButtonText: 'Save & Exit',
};

type Props = {
    beeEditorPreviewButton: ReactNode;
    editEmailButton: ReactNode;
    isDetailsExitBlocked: { value: boolean; key: number };
    isDetailsTouched: boolean;
    setIsDetailsActive: (isActive: boolean) => void;
    setIsDetailsExitBlocked: ({ value, key }: { value: boolean; key: number }) => void;
    setIsDetailsSaveSuccess: (isSuccess: boolean) => void;
    setIsDetailsTouched: (isTouched: boolean) => void;
};

// TODO: rename this component ContentDetails and move to ContentEmailBuilder folder
const ContentDetailsPage = ({
    editEmailButton,
    beeEditorPreviewButton,
    isDetailsExitBlocked,
    isDetailsTouched,
    setIsDetailsActive,
    setIsDetailsExitBlocked,
    setIsDetailsSaveSuccess,
    setIsDetailsTouched,
}: Props) => {
    const history = useHistory();

    const query = useQueryParams();
    const queryClient = useQueryClient();
    const templateId = query.get('id');

    const { data: template } = useTemplate({
        templateId,
        reactQueryOptions: {
            onSuccess: (template) => {
                if (isTemplate(template) && template) {
                    const { senderProfileId, subjectLine, preHeader } = template;

                    setSenderProfileId(senderProfileId);
                    setSubjectLine(subjectLine ?? '');
                    setPreHeader(preHeader ?? '');
                }
            },
        },
    });
    const { mutate: updateTemplate, isSuccess: isUpdateTemplateSuccess } = useUpdateTemplate(templateId, {
        onSuccess: () => {
            queryClient.invalidateQueries({
                // When we update a template, the cached network calls will be stale for the updated templates, so we need to invalidate the cached GET requests.
                predicate: (query: Query) => query.queryKey[0] === 'template-id',
            });
            setIsDetailsSaveSuccess(true);
        },
    });

    const {
        beeJson: templateBeeJson,
        html: templateHtml,
        name: templateName,
        senderProfileId: templateSenderProfileId,
        subjectLine: templateSubjectLine,
        preHeader: templatePreHeader,
    } = isTemplate(template) && template;

    const [senderProfileId, setSenderProfileId] = useState<number>(templateSenderProfileId);
    const [subjectLine, setSubjectLine] = useState(templateSubjectLine ?? '');
    const [preHeader, setPreHeader] = useState(templatePreHeader ?? '');

    const isSubjectLineError = subjectLine.length > 0 && subjectLine.length < 3;
    const isPreHeaderError = preHeader.length > 0 && preHeader.length < 3;

    const [isSubjectLinesDialogOpen, setIsSubjectLinesDialogOpen] = useState(false);

    const { data: suggestedSubjectLines } = useTemplateSuggestedSubjectLines({
        templateId: Number(templateId),
        reactQueryOptions: {
            enabled: isSubjectLinesDialogOpen,
        },
    });

    const { data: senderProfiles } = useSenderProfiles();
    const senderProfilesOptions =
        senderProfiles?.map(({ id, from_address }) => ({
            value: id,
            label: from_address,
        })) ?? [];

    const handleChangeInput = (e: any, onChange: (e: any) => void) => {
        setIsDetailsTouched(true);
        onChange(e.target.value);
    };

    const handleExit = () => history.push(`${URLPaths.CONTENT}?type=email`);

    const handleSave = () => {
        resetDetails();
        updateTemplate({
            beeJson: templateBeeJson,
            id: templateId,
            preHeader,
            senderProfileId,
            subjectLine,
            templateName,
        });
    };

    const handleCancelRouteLeaving = async () => {
        await resetDetails();
        await setIsDetailsActive(false);
    };

    const handleConfirmRouteLeaving = async () => {
        await handleSave();
        await setIsDetailsActive(false);
    };

    const getIsNavigationBlocked = (location: Location): boolean => {
        const isExiting = !location.pathname.includes(URLPaths.CONTENT_CREATE_EMAIL);
        return isExiting && isDetailsTouched;
    };

    const resetDetails = () => {
        setIsDetailsTouched(false);
        setIsDetailsExitBlocked({ value: false, key: isDetailsExitBlocked.key + 1 });
    };

    return (
        <>
            {isUpdateTemplateSuccess && <SuccessSnackbar successMessage="Send Details Saved." />}
            <RouteLeavingGuard
                dialogProps={dialogProps}
                onCancel={handleCancelRouteLeaving}
                onConfirm={handleConfirmRouteLeaving}
                shouldBlockNavigation={getIsNavigationBlocked}
                when={isDetailsExitBlocked}
            />
            <PageHeader
                icon={<PageInfoIcon fontSize="inherit" />}
                header="details"
                headerOthers={
                    <Stack direction="row" justifyContent="space-between" width="100%">
                        <Stack direction="row" alignItems="center" columnGap={1} ml={1}>
                            {editEmailButton}
                            {beeEditorPreviewButton}
                        </Stack>
                        <Stack direction="row" columnGap={2}>
                            <Button variant="text" onClick={handleExit}>
                                Exit
                            </Button>
                            <Button variant="outlined" onClick={handleSave}>
                                Save Details
                            </Button>
                        </Stack>
                    </Stack>
                }
            />
            <Grid container>
                <Grid item xs={12} md={6} lg={5} paddingRight={8} marginTop={10}>
                    <Stack rowGap={6.5}>
                        <Stack sx={inputSectionSx}>
                            <Stack sx={headerStackSx}>
                                <Typography variant="h6">from</Typography>
                                <Typography {...headerProps}>Who is sending this email?</Typography>
                            </Stack>
                            <Select
                                label={
                                    <InputLabel
                                        label="Sender profile"
                                        labelIconTooltipText="Who is sending this email?"
                                    />
                                }
                                onChange={(e) => handleChangeInput(e, setSenderProfileId)}
                                value={senderProfileId ?? ''}
                                values={senderProfilesOptions}
                            />
                        </Stack>
                        <Stack sx={inputSectionSx}>
                            <Stack sx={headerStackSx}>
                                <Typography variant="h6">subject</Typography>
                                <Typography {...headerProps}>What is this email about?</Typography>
                            </Stack>
                            <Stack rowGap={4}>
                                <Box position="relative">
                                    <TextField
                                        counterCurrent={subjectLine.length ?? 0}
                                        counterMax={225}
                                        data-test="email-content-page-subject-line"
                                        error={isSubjectLineError}
                                        helperText={
                                            isSubjectLineError && 'Subject line must have at least 3 characters'
                                        }
                                        inputProps={{ minLength: 3, maxLength: 255 }}
                                        label={
                                            <InputLabel
                                                label="Subject Line"
                                                labelIconTooltipText="A descriptive overview of your message that gives contacts a reason to open."
                                            />
                                        }
                                        onChange={(e) => handleChangeInput(e, setSubjectLine)}
                                        value={subjectLine}
                                        {...textFieldProps}
                                    />
                                    <IconButton
                                        buttonWrapperCss={{
                                            position: 'absolute',
                                            right: '-50px',
                                            top: '4px',
                                        }}
                                        handleClick={() => setIsSubjectLinesDialogOpen(true)}
                                        Icon={AutoFixHigh}
                                        title={`Click to use Cured's Subject Line Curator.`}
                                    />
                                </Box>
                                <SubjectLinesDialog
                                    isOpen={isSubjectLinesDialogOpen}
                                    subjectLines={suggestedSubjectLines}
                                    onClickSubjectLine={(index) => setSubjectLine(suggestedSubjectLines[index])}
                                    onClose={() => setIsSubjectLinesDialogOpen(false)}
                                />
                                <TextField
                                    counterCurrent={preHeader.length ?? 0}
                                    counterMax={100}
                                    error={isPreHeaderError}
                                    helperText={isPreHeaderError && 'Preview text must have at least 3 characters'}
                                    inputProps={{ minLength: 3, maxLength: 100 }}
                                    label={
                                        <InputLabel
                                            label="Preview Text"
                                            labelIconTooltipText="A quick glimpse into the content of your message that appears after the subject line."
                                        />
                                    }
                                    onChange={(e) => handleChangeInput(e, setPreHeader)}
                                    value={preHeader}
                                    {...textFieldProps}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={7} marginTop={5}>
                    <Box sx={previewSx}>
                        <HtmlRenderer htmlContent={templateHtml} />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default ContentDetailsPage;
