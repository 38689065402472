import { UseQueryOptions } from 'react-query';
import { Segment } from 'domains/segments/types';
import { CuredApiPaths } from 'models/enums';
import { PathWithID } from 'models/types';
import useQuery from '../useQuery';
import QueryKeys from './keys';

const useDirectMail = (contentId: number, reactQueryOptions?: UseQueryOptions<Segment, Error>) =>
    useQuery(
        QueryKeys.SEGMENT_ID(`${contentId}`),
        new PathWithID(CuredApiPaths.DIRECT_MAIL, contentId),
        reactQueryOptions
    );

export default useDirectMail;
