import { useState } from 'react';
import { useHistory } from 'react-router';
import { URLPaths } from 'models/enums';
import FolderNamingDialog from 'domains/core/components/folders/FolderNamingDialog';
import SegmentsPageLayout from '../SegmentsPageLayout';
import useSegmentsWithFolders from 'hooks/queries/useSegmentsWithFolders';
import useSegmentContentQueryManager from 'hooks/useSegmentContentQueryParams';
import useCreateSegmentFolder from 'hooks/mutations/useCreateSegmentFolder';
import { isRootFolder } from 'utils/folders';

const SegmentsPageWithFolders = () => {
    const [isCreateFolderModalVisible, setIsCreateFolderModalVisible] = useState(false);

    const history = useHistory();
    const { folderId, changeViewParams } = useSegmentContentQueryManager();
    const segmentsWithFoldersQuery = useSegmentsWithFolders({ folderId });
    const { refetch: refetchContent } = segmentsWithFoldersQuery;

    const isUserOnRootLevel = isRootFolder(folderId);

    const createFolder = (folderName: string) => {
        const body = {
            name: folderName,
        };
        saveFolder(body);
    };

    const onCreateFolderSuccess = (response: { id: number }) => {
        changeViewParams({ newFolderId: response.id });
        refetchContent();
        setIsCreateFolderModalVisible(false);
    };

    const { mutate: saveFolder, isLoading: isFolderSaving } = useCreateSegmentFolder({
        onSuccess: onCreateFolderSuccess,
    });

    const createButtonDropdownOptions = [
        { label: 'Audience', onClick: () => history.push(URLPaths.SEGMENT_CREATE) },
        { label: 'Folder', onClick: () => setIsCreateFolderModalVisible(true) },
    ];

    return (
        <>
            <FolderNamingDialog
                disableActionButton={isFolderSaving}
                initialValue="Untitled Folder"
                isDialogVisible={isCreateFolderModalVisible}
                onClose={() => setIsCreateFolderModalVisible(false)}
                onSubmit={createFolder}
                title="create new folder"
            />
            <SegmentsPageLayout
                createButtonDropdownOptions={createButtonDropdownOptions}
                isUserOnRootLevel={isUserOnRootLevel}
                segmentsContentQuery={segmentsWithFoldersQuery}
            />
        </>
    );
};

export default SegmentsPageWithFolders;
