import { Stack } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from 'domains/core/components/IconButton';
import Table, { ColumnOption, renderDate } from 'domains/core/components/Table';
import SegmentNameInput from 'domains/segments/components/SegmentNameInput';
import { SegmentType, Segment } from 'domains/segments/types';
import { MutationStatus, URLPaths } from 'models/enums';
import SegmentDetails from './SegmentDetails';
import { hasInvalidRelativeDates } from 'domains/segments/utils';
import downloadFile from 'helpers/downloadFile/downloadFile';
import useMutationStatus from 'hooks/useMutationStatus';
import MutationKeys from 'hooks/mutations/keys';
import useDeleteSegment from 'hooks/mutations/useDeleteSegment';
import useFeatureSettings from 'hooks/queries/useFeatureSettings';
import useContacts from 'hooks/queries/useContacts';
import MoreOptionsDropdown from 'domains/core/components/MoreOptionsDropdown/MoreOptionsDropdown';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar/ErrorSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar/SuccessSnackbar';
import InfoSnackbar from 'domains/core/components/Snackbars/InfoSnackbar/InfoSnackbar';
import ConfirmationDialog from 'domains/core/components/ConfirmationDialog';
import { UseQueryResult } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Folder as FolderIcon } from '@mui/icons-material';
import { Folder, FolderLocation } from 'models/types';
import useSegmentContentQueryManager from 'hooks/useSegmentContentQueryParams';
import FolderEmptyState from 'domains/core/components/folders/FolderEmptyState/FolderEmptyState';

const renderRowSubComponent = (row: any) => {
    if (row.original.isExpandable) {
        return <SegmentDetails segment={row.original} />;
    }
    return null;
};

export type Props<SegmentType extends Segment | Folder> = {
    isNestedContent?: Boolean;
    segmentsContentQuery: UseQueryResult<SegmentType[], Error>;
};

const allContactsRowName = 'All Contacts';

/**
 * A table used for displaying audiences in the audiences page.
 * @returns The React node created by this component.
 */
const SegmentsTable = <SegmentType extends Segment | Folder>({
    isNestedContent,
    segmentsContentQuery: { data: queryData, isRefetching, refetch },
}: Props<SegmentType>) => {
    const { audienceFolders: hasAudienceFolders } = useFlags();

    const [audienceToDownload, setAudienceToDownload] = useState(null);
    const [audienceToDelete, setAudienceToDelete] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const history = useHistory();

    const { changeViewParams } = useSegmentContentQueryManager();
    const shouldShowEmptyTable = !queryData?.length && isNestedContent;

    const {
        isLoading: isFeatureSettingsLoading,
        data: featureSettings,
        isSuccess: isFeatureSettingsSuccess,
    } = useFeatureSettings();

    const {
        isLoading: isContactsDownloadLoading,
        isError: isContactsDownloadError,
        isSuccess: isContactsDownloadSuccess,
    } = useContacts({
        downloadCSV: true,
        options: {
            limit: 'all',
            segmentId: audienceToDownload?.id,
        },
        reactQueryOptions: {
            enabled: !!audienceToDownload,
            onError: () => {
                setErrorMessage(`Problem with downloading "${audienceToDownload?.name}." Try again.`);
            },
            onSuccess: (csvFile) => {
                setSuccessMessage(`"${audienceToDownload?.name}" successfully downloaded.`);
                downloadFile({
                    file: csvFile.toString(),
                    fileType: 'csv',
                    fileName: audienceToDownload?.name,
                });
            },
        },
    });

    const { isLoading: isDeleteSegmentLoading, mutate: deleteSegment } = useDeleteSegment(`${audienceToDelete?.id}`, {
        onError: (error: Error) => setErrorMessage(error.message),
        onSuccess: () => {
            setSuccessMessage(`"${audienceToDelete.name}" successfully deleted.`);
            refetch();
        },
    });

    const toggleDeleteModalOpen = useCallback(
        (segment?: Segment, isConfirm?: boolean) => {
            isConfirm ? deleteSegment() : setAudienceToDelete(segment);
            setIsDeleteModalOpen(!!segment);
        },
        [deleteSegment]
    );

    const data: SegmentType[] = useMemo(
        () =>
            queryData?.map((content: SegmentType) => ({
                ...content,
                dateCreated: new Date(content.createdTimestamp),
                dateUpdated: new Date(content.lastModifiedTimestamp),
                id: content.id,
                isExpandable: hasAudienceFolders ? (content.type === SegmentType.AUDIENCE ? true : false) : true,
                type: hasAudienceFolders ? `${content.type}` : SegmentType.AUDIENCE,
            })),
        [queryData, hasAudienceFolders]
    );

    const renderRowIcon = (value: any) => {
        if (value.row.original.type === SegmentType.AUDIENCE)
            return (
                <IconButton
                    ariaLabel="show audience details"
                    handleClick={() => {
                        value.row.toggleRowSelected();
                        value.row.getToggleRowExpandedProps().onClick();
                    }}
                    Icon={value.row.isExpanded ? ExpandLessIcon : ExpandMoreIcon}
                    color="primary"
                />
            );
        if (value.row.original.type === SegmentType.FOLDER)
            return <FolderIcon color="primary" sx={{ width: '40px' }} />;
    };

    const columns: ColumnOption[] = useMemo(() => {
        const nameColumn: ColumnOption = {
            Header: 'name',
            accessor: 'name',
            align: 'left',
            sortType: 'string',
            Cell: (value: any) => (
                <Stack direction="row" alignItems="center" columnGap={1}>
                    {renderRowIcon(value)}
                    {value.cell.row.original.name === allContactsRowName ||
                    value.row.original.type === SegmentType.FOLDER ? (
                        <>{value.cell.value}</>
                    ) : (
                        <SegmentNameInput id={value.cell.row.original.id} name={value.cell.value} />
                    )}
                </Stack>
            ),
        };

        const dateCreatedColumn: ColumnOption = {
            Header: 'date created',
            accessor: 'dateCreated',
            sortType: 'datetime',
            Cell: renderDate,
        };

        const moreOptionsColumn: ColumnOption = {
            Header: '',
            accessor: 'options',
            align: 'right',
            disableSortBy: true,
            Cell: (value: any) => {
                const rowData = value.row.original;

                const isDeleteEnabled = value.row.values.name !== allContactsRowName && rowData.canDelete;
                const isDownloadEnabled = isFeatureSettingsSuccess && featureSettings?.allow_mass_contact_retrieval;

                const segmentMenuItems = [
                    {
                        name: 'View Contacts',
                        onClick: () => {
                            history.push(URLPaths.SEGMENTS_CONTACTS, {
                                segmentId: rowData.id,
                                segmentName: rowData.name,
                            });
                        },
                    },
                    {
                        disabled: hasInvalidRelativeDates(value.row.values),
                        name: 'Copy Audience Filters',
                        onClick: () => {
                            history.push(URLPaths.SEGMENT_CREATE, {
                                segmentDefinition: rowData.segmentDefinition,
                            });
                        },
                    },
                    ...(isDownloadEnabled
                        ? [
                              {
                                  name: 'Download Contacts',
                                  onClick: () => setAudienceToDownload(rowData),
                              },
                          ]
                        : []),
                    ...(isDeleteEnabled
                        ? [
                              {
                                  name: 'Delete',
                                  onClick: () => toggleDeleteModalOpen(rowData),
                              },
                          ]
                        : []),
                ];

                const folderMenuItems = [
                    {
                        name: 'Open',
                        onClick: () => changeViewParams({ newFolderId: rowData.id }),
                    },
                    {
                        name: 'Rename',
                        onClick: null,
                    },
                    ...(isDeleteEnabled
                        ? [
                              {
                                  name: 'Delete',
                                  onClick: null,
                              },
                          ]
                        : []),
                ];

                const menuItems = rowData.type === SegmentType.FOLDER ? folderMenuItems : segmentMenuItems;

                return <MoreOptionsDropdown menuItems={menuItems} />;
            },
        };

        return [nameColumn, dateCreatedColumn, moreOptionsColumn];
    }, [
        isFeatureSettingsSuccess,
        featureSettings?.allow_mass_contact_retrieval,
        history,
        toggleDeleteModalOpen,
        changeViewParams,
    ]);

    const stickyRowFilter = useCallback((row: any) => row.original.name === allContactsRowName, []);

    const deleteSegmentStatus = useMutationStatus(MutationKeys.DELETE_SEGMENT);

    const isError = isContactsDownloadError || deleteSegmentStatus === MutationStatus.ERROR;
    const isLoading = isContactsDownloadLoading || isDeleteSegmentLoading;
    const isSuccess = isContactsDownloadSuccess || deleteSegmentStatus === MutationStatus.SUCCESS;

    const infoMessage = isContactsDownloadLoading
        ? `Downloading "${audienceToDownload?.name}"`
        : isDeleteSegmentLoading
        ? `Deleting "${audienceToDelete?.name}"`
        : '';

    const onRowClick = (row: any) => {
        const id = row.original.id;
        if (row.original.type === SegmentType.FOLDER) {
            changeViewParams({ newFolderId: id });
        }
    };

    if (isFeatureSettingsLoading) return;

    return (
        <>
            {isError && <ErrorSnackbar errorMessage={errorMessage} />}
            {isLoading && <InfoSnackbar successMessage={infoMessage} />}
            {isSuccess && <SuccessSnackbar successMessage={successMessage} />}
            <ConfirmationDialog
                dialogProps={{
                    confirmButtonText: 'Delete',
                    contentText: `You are about to delete "${audienceToDelete?.name}." This action cannot be undone.`,
                    title: 'Delete Audience',
                }}
                open={isDeleteModalOpen}
                onConfirm={() => toggleDeleteModalOpen(null, true)}
                onModalClose={() => toggleDeleteModalOpen()}
            />
            <Table
                columns={columns}
                data={data}
                defaultSortingRules={[{ id: 'dateCreated', desc: true }]}
                isRefetching={isRefetching}
                stickyRow
                stickyRowFilter={stickyRowFilter}
                renderRowSubComponent={renderRowSubComponent}
                refetch={refetch}
                {...(hasAudienceFolders && { onRowClick })}
            />
            {shouldShowEmptyTable && <FolderEmptyState location={FolderLocation.AUDIENCE_FOLDER} />}
        </>
    );
};

export default SegmentsTable;
