import { Stack, Typography } from '@mui/material';
import emptyFolderPageImage from 'assets/images/svg/empty-folder-page-icon.svg';
import useContentQueryManager from 'hooks/useContentQueryParams';
import { FolderLocation } from 'models/types';

export type Props = {
    location: FolderLocation;
};

const FolderEmptyState = ({ location }: Props) => {
    const { type: contentType } = useContentQueryManager();

    const getHeaderText = () => {
        if (location === FolderLocation.AUDIENCE_FOLDER) {
            return 'move audiences here';
        }
        return location === FolderLocation.CONTENT_FOLDER && !!contentType
            ? 'no files found here'
            : 'move files or folders here';
    };

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                backgroundImage: `url(${emptyFolderPageImage})`,
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                height: '80%',
            }}
        >
            <Typography fontSize="24px" fontWeight="500">
                {getHeaderText()}
            </Typography>
            <Typography variant="body2" maxWidth="270px" textAlign="center" pt={1}>
                {!!contentType ? 'adjust your filters or look in a different folder' : 'or use “Create” button'}
            </Typography>
        </Stack>
    );
};

export default FolderEmptyState;
