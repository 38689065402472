import { Folder } from 'models/types';

export type BeeConfig = {
    client_uid: string;
    mergeTags: MergeTag[];
    contentDefaults: any;
    editorFonts: any;
    autosave: number | boolean;
    defaultForm?: any;
};

export type MergeTag = {
    name: string;
    value: string;
    previewValue: string;
};

export type SenderProfile = {
    id: number;
    name: string;
    from_address: string;
    reply_address: string;
};

export type DirectMailSenderProfile = {
    id: number;
    name: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    streetAddress?: string;
    streetAddress2?: string;
    city?: string;
    stateCode?: string;
    email?: string;
    phoneNumber?: string;
    jobTitle?: string;
    zipCode?: string;
    countryCode?: string;
};

export enum ContentType {
    ALL = 'all',
    FOLDER = 'folder',
    EMAIL = 'email',
    PAGE = 'page',
}

export type Template = {
    id: number;
    name: string;
    html: string;
    createdTimestamp: string;
    lastModifiedTimestamp: string;
    isLayout: boolean;
    beeJson?: string;
    preHeader?: string;
    senderProfileId?: number;
    subjectLine?: string;
    // This is only used by the type guard
    templateFields?: any;
    // This is neccessary as we are passing Template | LandingPage | Content as the type to the
    // ContentTable component. We have a new key 'type' on the content API and hence this needs to
    // be added as optional to both Template and LandingPage types.
    type?: string;
};

export type LandingPage = {
    beeJson?: string;
    createdTimestamp: string;
    deletedTimestamp?: string;
    id: number;
    lastModifiedTimestamp: string;
    name: string;
    parentFolderId?: number;
    type?: string;
};

export type CreateLandingPageInput = Pick<LandingPage, 'beeJson'> &
    Partial<Pick<LandingPage, 'name' | 'parentFolderId'>>;
export type CreateTemplateInput = Pick<Template, 'beeJson'> & { templateName?: string; parentFolderId?: number };

export type UpdateLandingPageInput = Pick<LandingPage, 'beeJson'> &
    Partial<Pick<LandingPage, 'name' | 'parentFolderId'>> & { id: string };
export type UpdateTemplateInput = Pick<Template, 'beeJson'> & {
    id: string;
    parentFolderId?: number;
    preHeader?: string;
    senderProfileId?: number;
    subjectLine?: string;
    templateName?: string;
};

export function isTemplate(obj: any): obj is Template {
    return obj && typeof obj === 'object' && 'beeJson' in obj && 'templateFields' in obj;
}
export function isLandingPage(obj: any): obj is LandingPage {
    return obj && typeof obj === 'object' && 'beeJson' in obj && !('templateFields' in obj);
}

export enum ContentTypeEnumForBackend {
    'Email' = 'email',
    'Landing Page' = 'landing_page',
    'Folder' = 'folder',
    'Default' = '',
}

export enum ContentTypeForComparison {
    FOLDER = 'Folder',
    LANDING_PAGE = 'Landing Page',
    EMAIL = 'Email',
    DEFAULT = 'Default',
}

export type FolderLineage = {
    id: number;
    name: string;
    level: number;
};

export type FolderBreadcrumbData = {
    id: number;
    name: string;
    type: ContentTypeForComparison;
    level?: number;
};

export type ContentModalContentType = Template | LandingPage | Folder | FolderBreadcrumbData;

export enum ContentModalTypes {
    DELETE = 'Delete',
    RENAME = 'Rename',
    MOVE = 'Move',
}

export const RootFolder = {
    name: 'All Content',
    id: -1,
    type: ContentTypeForComparison.FOLDER,
};
